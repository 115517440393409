export const copyright = (
	<span
		style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", margin: "auto 16px" }}
	>
		<span
			style={{ display: "inline-block", marginRight: "0.3em", flexShrink: 0 }}
		>{`© Cloudokyo ${new Date().getFullYear()}.`}</span>
		<span style={{ display: "inline-block", flexShrink: 0 }}>{`All rights reserved.`}</span>
	</span>
);

import { cfg_page_loader } from "df-frontend-02/dist/sys/modules/page-loader/cfg-page-loader";
import { useEffect } from "react";

cfg_page_loader.HPageNotFound = props => {
	return <H404 {...props} />;
};

function H404(props: { page_path: string }) {
	useEffect(() => {
		const timeoutWorker = setTimeout(() => {
			window.location.href = "/";
		}, 5000);
		return () => clearTimeout(timeoutWorker);
	}, []);

	const { page_path } = props;
	console.warn(`Page ${page_path} is not found.`);

	return (
		<div style={{ width: "100vw", height: "100vh" }}>
			<div
				style={{
					display: "flex",
					height: "100%",
					flexDirection: "column",
					justifyContent: "center",
					margin: "auto",
				}}
			>
				<p style={{ fontSize: "80px", textAlign: "center", color: "#898989" }}>{`404!`}</p>
				<p
					style={{ fontSize: "20px", textAlign: "center", color: "#898989" }}
				>{`Page is not found`}</p>
			</div>
		</div>
	);
}

import { cfg_translation } from "df-frontend-02/dist/sys/modules/translation-server/cfg-translation";
cfg_translation.local_items = [
	{
		code: "MENU26",
		all_text: {
			"en-US": "Home",
			"zh-CN": "首页",
			"zh-TW": "首頁",
		},
	},
];

//--------------------------------------------------------------------------------------
// Call initPackage first, which will configure the library
// initialization
//--------------------------------------------------------------------------------------

//Uncomment this line to use static translation file, no loading from server
import "./static-translation";

import "df-helpers/dist/array-ext/array-ext";
import "./init-be-app-info";
import "./init-events";
import "./index.scss";
import { registerAppPages } from "./register-app-pages";

//--------------------------------------------------------------------------------------
// Start the page
// Import the following after initPackage() is called.
//--------------------------------------------------------------------------------------
import { frontend_framework } from "df-frontend-02/dist/index-internal";
import ReactDOM from "react-dom/client";
import "./init-404";

registerAppPages();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(frontend_framework);

/** Refer to the same file in df-backend-02 for more configurable fields */

/* ... removed ...*/

export const be_app_info: Partial<TBEAppInfo> = {
	/** Name of the project. The database will be created based on this name */
	prj_name: "cloudokyo",

	/** Major, minor, patch */
	be_app_ver: "1.0.00",

	//----------------------------------------------------
	/* ... removed ...*/
	//====================================================

	sales_power: {
		enabled: true,
		//----------------------------------------------------
		/* ... removed ...*/
		//====================================================
	},

	//[END-NO-SYNC-TO-FE]

	//----------------------------------------------//
	//					Languages					//
	//----------------------------------------------//
};

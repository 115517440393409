// Frontend configuration file
// Do not import. This is loaded at startup

import {
	sys_menu_language,
	sys_menu_table_mgr,
	sys_menu_user_mgr,
	sys_menu_user_profile,
} from "df-frontend-02/dist/apps/app/app-predef-menu";
import { copyright } from "./copyright";

export function getAppInfo(): Partial<IAppInfo> {
	const ret: Partial<IAppInfo> = {
		app_name: "Cloudokyo",
		//browser title
		doc_title_template: "{0} - Cloudokyo",
		footer_line: copyright,

		path_after_logout: "/",
		path_after_login: "/",
		path_to_login: "/sign-in",
		merge_with_db: false, // This will disable loading app info from server db
		login_types: {
			ganjing_auth: false,
			google: false,
			name_pwd: false,
		},

		navbar: {
			color: "black",

			/** left menu will completely replace the menu in the package */
			left_menu: [
				{
					title: trans("MENU01", "Home"),
					roles: ["everyone"],
					href: "/",
				},
				//[CODE-GEN-MENU]
				// /*BEGIN:drm*/
				// {
				// 	title: <span style={{color:"red", backgroundColor:'lightblue'}}>drm</span>,
				// 	roles: ['admin'],
				// 	href: 'drm',
				// },
				// /*END:drm*/
				{
					title: "Help",
					roles: ["admin"],
					sub_menus: [
						{
							title: "CMS",
							roles: ["everyone"],
							href: "help/cms",
						},
						{
							title: "Component samples",
							roles: ["everyone"],
							href: "help/component-samples",
						},
						{
							title: "Cypress test",
							roles: ["everyone"],
							href: "help/cypress-test",
						},
						{
							title: "-",
							roles: ["everyone"],
						},
						{
							title: "Sample table - Score",
							roles: ["everyone"],
							href: "/data-grid/resource-score",
						},
						{
							title: "Sample page - My page",
							roles: ["everyone"],
							href: "/my-page",
						},
					],
				},
				{
					title: sys_menu_user_mgr,
				},
				{
					title: sys_menu_table_mgr,
				},
			],
			right_menu: [{ title: sys_menu_language }, { title: sys_menu_user_profile }],
			sticky: false,
		},

		// apiPort: number, //api port number

		// urlAfterLogin: string;
		// urlHome: string;

		// beEndPoint: () => "http://localhost:3333",

		// show_breadcrumb: true,
	};
	return ret;
}
